import React, { useState } from "react";
import DemandCurve from "./DemandCurve";
import SupplyCurve from "./SupplyCurve";
import DemandCurveEmulator from "./DemandCurveEmulator";
import SupplyCurveEmulator from "./SupplyCurveEmulator";
import DemandCurveMobile from "./DemandCurveMobile";
import SupplyCurveMobile from "./SupplyCurveMobile";
import styles from "./styles";
import price from "images/price.svg";
import quantity from "images/quantity.svg";
import { emulatorScreen, mobileScreen } from "utils/styleUtils";
import { useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import questionMark from "../../../../images/double_line_icons/models/question-mark.svg";

const LineDotBothHandler = (props) => {
  const {
    showDragMessage,
    setIsSupplyMoved,
    setIsDemandMoved,
    classes,
    ...otherProps
  } = props;

  const theme = useTheme();
  const isEmulator = useMediaQuery(emulatorScreen(theme));
  const isMobile = useMediaQuery(mobileScreen(theme));
  const [demandMoved, setDemandMoved] = useState(false);
  const [supplyMoved, setSupplyMoved] = useState(false);

  console.log("demandMoved", demandMoved);
  console.log("supplyMoved", supplyMoved);

  const demandCurveResponsive = () => {
    if (isMobile)
      return (
        <DemandCurveMobile
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
        />
      );
    else if (isEmulator)
      return (
        <DemandCurveEmulator
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
          setIsDemandMoved={setIsDemandMoved}
        />
      );
    else
      return (
        <DemandCurve
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
          setIsDemandMoved={setIsDemandMoved}
        />
      );
  };

  const supplyCurveResponsive = () => {
    if (isMobile)
      return (
        <SupplyCurveMobile
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
        />
      );
    else if (isEmulator)
      return (
        <SupplyCurveEmulator
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
          setIsSupplyMoved={setIsSupplyMoved}
        />
      );
    else
      return (
        <SupplyCurve
          {...otherProps}
          demandMoved={demandMoved}
          setDemandMoved={setDemandMoved}
          supplyMoved={supplyMoved}
          setSupplyMoved={setSupplyMoved}
          setIsSupplyMoved={setIsSupplyMoved}
        />
      );
  };

  return (
    <Grid className={classes.graphLines}>
      <img src={price} className={classes.graphYLable} />
      {otherProps.secondStage ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={questionMark} className={classes.questionMark} />
        </div>
      ) : (
        <>
          {supplyCurveResponsive()}
          {demandCurveResponsive()}
        </>
      )}
      <img src={quantity} className={classes.graphXLable} />
    </Grid>
  );
};

export default withStyles(styles)(LineDotBothHandler);
