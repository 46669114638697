// import bracelets from "../../images/circleIcons/equalibrium/bracelets.png";
// import icecream from "../../images/circleIcons/equalibrium/Icecream.png";
// import silver from "../../images/circleIcons/equalibrium/silver.png";
// import avocado from "../../images/circleIcons/equalibrium/avocado.png";
// import vase from "../../images/circleIcons/equalibrium/vase.png";
// import gaming from "../../images/circleIcons/equalibrium/gaming.png";
// import soda from "../../images/circleIcons/equalibrium/soda.png";
// import alcohol from "../../images/circleIcons/equalibrium/alcohol.png";
// import physicians from "../../images/circleIcons/equalibrium/physicians.png";
// import chapstick from "../../images/circleIcons/equalibrium/chapstick.png";
// import eCigarettes from "../../images/circleIcons/equalibrium/eCigarettes.png";
// import eyeSurgery from "../../images/circleIcons/equalibrium/eyeSurgery.png";
// import oil from "../../images/circleIcons/equalibrium/oil.png";

import Handsanitizer from "../../images/double_line_icons/Handsanitizer.png";
import Riseshare from "../../images/double_line_icons/Riseshare.png";
import Honey from "../../images/double_line_icons/Honey.png";
import UsedCar from "../../images/double_line_icons/Used-car.png";
import Internet from "../../images/double_line_icons/Internet.png";
import HulaHoops from "../../images/double_line_icons/Hula-hoops.png";
import Earbuds from "../../images/double_line_icons/Earbuds.png";
import Eyeglasses from "../../images/double_line_icons/Eyeglasses.png";
import Gym from "../../images/double_line_icons/Gym.png";
import Daycare from "../../images/double_line_icons/Daycare.png";

const questionAnswers = [
  // {
  //   title: "CHART TITLE",
  //   question: "breaking new",
  //   subQuestion: "Question",
  //   demandAnswer: "What happens to graph",
  //   supplyAnswer: "What happens to graph",
  //   briefness: "Answer",
  // graphType: 'supply curve/demand curve',
  // },

  //note!
  // Down == decrease
  // Up == increase
  {
    question:
      'Parasites are causing huge declines in bee colonies around the U.S. Meanwhile, "hot honey," honey with a kick, gains lots of popularity in the South.',
    subQuestion: "Consider the market for honey. What happens?",
    title: "MARKET FOR HONEY",
    demandAnswer: "shifts right",
    supplyAnswer: "shifts left",
    priceAnswer: "Up",
    quantityAnswer: "Indeterminate",
    briefness:
      "A bee shortage will increase the costs for honey producers, causing a decrease in supply—shifting it to the left. However, thanks to the new popularity of hot honey, demand will increase and shift to the right.",
    secondQuestion:
      "Given that demand increased and supply decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is higher and the new equilibrium quantity is indeterminate. When supply decreases, this leads to a decrease in the equilibrium quantity. Conversely, when demand increases, this leads to an increase in the equilibrium quantity, so the two shifts occurring at the same time mean that the new equilibrium quantity could be higher or lower depending on the magnitude of the shifts, as seen on the graphs. However, a decrease in supply and an increase in demand both cause the equilibrium price to increase and therefore, regardless of the magnitude of the shifts, the new equilibrium price will be higher.",
    icon: Honey,
    secondSubQuestion:
      "Given that demand increased and supply decreased, what happens in the new equilibrium price and quantity?",
    graphType: "supply curve",
    model: 1,
  },
  {
    title: "MARKET FOR HAND SANITIZER",
    question:
      "At the beginning of the COVID-19 pandemic, individuals become more concerned with hand sanitizing. Several alcohol distilleries decide to shift production from alcohol to hand sanitizer.",
    subQuestion: "Consider the market for hand sanitizer. What happens?",
    icon: Handsanitizer,
    demandAnswer: "shifts right",
    supplyAnswer: "shifts right",
    priceAnswer: "Indeterminate",
    quantityAnswer: "Up",
    graphType: "supply curve",
    briefness:
      "If consumers become more concerned about hygiene and hand washing, the demand for hand sanitizer will increase. An increase in the number of hand sanitizer producers means that the supply of hand sanitizer will also increase—represented by a shift of the supply curve to the right.",
    secondQuestion:
      "Given that demand and supply increased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is indeterminate and the new equilibrium quantity is higher. When supply increases, this leads to a decrease in the equilibrium price. Conversely, when demand increases, this leads to an increase in the equilibrium price, so the two shifts occurring at the same time mean that the equilibrium price could be higher or lower depending on the magnitudes of the shifts, as seen in the graphs. However, an increase in supply and an increase in demand both cause the equilibrium quantity to increase, and therefore, regardless of the magnitudes of the shifts, the new equilibrium quantity will be higher.",
    secondSubQuestion:
      "Given that demand and supply increased, what happens in the new equilibrium price and quantity?",
    model: 3,
  },
  {
    question:
      "During the COVID-19 pandemic, both ridesharing drivers and passengers are worried about traveling in a car with strangers and reduce the number of trips they take.",
    subQuestion: "Consider the market for ridesharing services. What happens?",
    title: "MARKET FOR RIDESHARING SERVICES",
    demandAnswer: "shifts left",
    supplyAnswer: "shifts left",
    priceAnswer: "Indeterminate",
    quantityAnswer: "Down",
    briefness:
      "If consumers become more concerned about travelling with strangers, the demand for ridesharing service will decrease—represented by a shift of the demand curve to the left. Furthermore, if drivers are also worried about driving unknown passengers, supply will also decrease and shift to the left.",
    secondQuestion:
      "Given that demand and supply decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is indeterminate and the new equilibrium quantity is lower. When supply decreases, this leads to an increase in the equilibrium price. Conversely, when demand decreases, this leads to a decrease in the equilibrium price, so the two shifts occurring at the same time mean that the new equilibrium price is indeterminate: it could be higher or lower depending on which shift is larger, as seen on the graphs. However, a decrease in supply and a decrease in demand both cause the equilibrium quantity to decrease, and therefore, regardless of the magnitudes of the shifts, the new equilibrium quantity will be lower.",
    icon: Riseshare,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand and supply decreased, what happens in the new equilibrium price and quantity?",
    model: 2,
  },
  {
    question: "Computer chip shortages increase the price of new cars.",
    subQuestion: "Consider the market for used cars. What happens?",
    title: "MARKET FOR USED CARS",
    demandAnswer: "shifts right",
    supplyAnswer: "nothing",
    priceAnswer: "Up",
    quantityAnswer: "Up",
    briefness:
      "An increase in the price of new cars will increase the demand for their substitutes, such as used cars. This will increase the demand for used cars, represented by a shift of the demand curve to the right.",
    secondQuestion:
      "Given that demand increased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price and quantity are both higher. When demand increases, this leads to a higher new equilibrium price and quantity.",
    icon: UsedCar,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand increased, what happens in the new equilibrium price and quantity?",
    model: 6,
  },
  {
    question:
      "Several new internet service providers enter the market. Meanwhile, new satellites lower the cost of providing internet service.",
    subQuestion: "Consider the market for internet services. What happens?",
    title: "MARKET FOR INTERNET SERVICES",
    demandAnswer: "nothing",
    supplyAnswer: "shifts right",
    priceAnswer: "Down",
    quantityAnswer: "Up",
    briefness:
      "An increase in the number of sellers increases supply and shifts the supply curve to the right. A decrease in the cost of providing internet services also shifts the supply curve to the right.",
    secondQuestion:
      "Given that supply increased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is lower and the new equilibrium quantity is higher. When supply increases, this leads to a lower equilibrium price and a higher equilibrium quantity.",
    icon: Internet,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that supply increased, what happens in the new equilibrium price and quantity?",
    model: 4,
  },
  {
    question:
      "Hula hoops are hailed on TikTok as the latest, greatest weight loss device. Meanwhile, all stores that import plastic toys into the U.S. must pay a new tariff.",
    subQuestion: "Consider the market for hula hoops. What happens?",
    title: "MARKET FOR HULA HOOPS",
    demandAnswer: "shifts right",
    supplyAnswer: "shifts left",
    priceAnswer: "Up",
    quantityAnswer: "Indeterminate",
    briefness:
      "A rise in the popularity of Hula hoops increased demand—represented by a shift to the right. A tariff on hula hoops increased input costs—decreasing supply and shifting it to the left.",
    secondQuestion:
      "Given that demand increased and supply decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is higher and the new equilibrium quantity is indeterminate. When supply decreases, this leads to a decrease in the equilibrium quantity. Conversely, when demand increases, this leads to an increase in the equilibrium quantity, so the two shifts occurring at the same time mean that the new equilibrium quantity could be higher or lower depending on the magnitude of the shifts, as seen on the graphs. However, a decrease in supply and an increase in demand both cause the equilibrium price to increase and therefore, regardless of the magnitude of the shifts, the new equilibrium price will be higher.",
    icon: HulaHoops,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand increased and supply decreased, what happens in the new equilibrium price and quantity?",
    model: 1,
  },
  {
    question:
      "Consumers expect that a superior type of wireless earbuds will launch in six months. Meanwhile, the price of computer chips that earbud producers use for the production of the current earbuds has increased.",
    subQuestion: "Consider the market for earbuds. What happens?",
    title: "MARKET FOR EARBUDS",
    demandAnswer: "shifts left",
    supplyAnswer: "shifts left",
    priceAnswer: "Indeterminate",
    quantityAnswer: "Down",
    briefness:
      "If consumers think that a better wireless earbud will be out soon, they will hold off on making purchases. This will decrease the demand for earbuds—represented by a shift of the demand curve to the left. An increase in producers' current costs will decrease supply and shift the supply curve to the left.",
    secondQuestion:
      "Given that demand and supply decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is indeterminate and the new equilibrium quantity is lower. When supply decreases, this leads to an increase in the equilibrium price. Conversely, when demand decreases, this leads to a decrease in the equilibrium price, so the two shifts occurring at the same time mean that the new equilibrium price is indeterminate: it could be higher or lower depending on which shift is larger, as seen in the featured graphs. However, a decrease in supply and a decrease in demand both cause the equilibrium quantity to decrease, and therefore, regardless of the magnitudes of the shifts, the new equilibrium quantity will be lower.",
    icon: Earbuds,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand and supply decreased, what happens in the new equilibrium price and quantity?",
    model: 2,
  },
  {
    question:
      "The price of laser eye surgeries that reduce the need to wear glasses falls dramatically. Meanwhile, new technology lowers the costs to produce eye contacts, a substitute for eyeglasses.",
    subQuestion: "Consider the market for eyeglasses. What happens?",
    title: "MARKET FOR EYEGLASSES",
    demandAnswer: "shifts left",
    supplyAnswer: "nothing",
    priceAnswer: "Down",
    quantityAnswer: "Down",
    briefness:
      "Both events affect the demand for eyeglasses. Because the relative price of substitutes has fallen, the demand for eyeglasses will decrease, shifting the demand curve to the left—without affecting supply.",
    secondQuestion:
      "Given that demand decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price and quantity are both lower. When demand decreases, this leads to a lower equilibrium price and quantity.",
    icon: Eyeglasses,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand decreased, what happens in the new equilibrium price and quantity?",
    model: 5,
  },
  {
    question:
      "A celebrity suffers from a bacterial infection contracted while working out a gym. Meanwhile, a new exercise craze focusing on outdoor workouts sweeps the nation.",
    subQuestion: "Consider the market for gyms. What happens?",
    title: "MARKET FOR GYMS",
    demandAnswer: "shifts left",
    supplyAnswer: "nothing",
    priceAnswer: "Down",
    quantityAnswer: "Down",
    briefness:
      "Both these events affect demand for gyms. Both would lower the preference for working out in a gym and therefore the demand curve would shift to the left.",
    secondQuestion:
      "Given that demand decreased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price and quantity are both lower. When demand decreases, this leads to a lower equilibrium price and quantity.",
    icon: Gym,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand decreased, what happens in the new equilibrium price and quantity?",
    model: 5,
  },
  {
    question:
      "A new baby boom takes place in the U.S. At the same time, the government starts subsidizing daycare centers.",
    subQuestion: "Consider the market for daycare centers. What happens?",
    title: "MARKET FOR DAYCARE CENTERS",
    demandAnswer: "shifts right",
    supplyAnswer: "shifts right",
    priceAnswer: "Indeterminate",
    quantityAnswer: "Up",
    briefness:
      "A baby boom will increase the demand for goods and services related to babies, including daycare centers, and shift the demand curve to the right. A subsidy for daycare centers will increase the supply of daycare centers—shifting the supply curve to the right.",
    secondQuestion:
      "Given that demand and supply increased, what happens in the new equilibrium?",
    secondBriefness:
      "The new equilibrium price is indeterminate and the new equilibrium quantity is higher. When supply increases, this leads to a decrease in the equilibrium price. Conversely, when demand increases, this leads to an increase in the equilibrium price, so the two shifts occurring at the same time mean that the equilibrium price could be higher or lower depending on the magnitudes of the shifts, as seen on the graphs. However, an increase in supply and an increase in demand both cause the equilibrium quantity to increase, and therefore, regardless of the magnitudes of the shifts, the new equilibrium quantity will be higher.",
    icon: Daycare,
    graphType: "supply curve",
    secondSubQuestion:
      "Given that demand and supply increased, what happens in the new equilibrium price and quantity?",
    model: 3,
  },

  // {
  //   title: "MARKET FOR BRACELETS",
  //   question:
  //     "Fashion magazines: Wearing multiple bracelets is the hot new trend.",
  //   subQuestion: "Consider the market for bracelets. What happens?",
  //   icon: bracelets,
  //   demandAnswer: "shifts right",
  //   supplyAnswer: "shifts right",
  //   graphType: "supply curve",
  //   briefness:
  //     "If wearing multiple bracelets is now in style, this means that demand for bracelets will increase. The demand curve will shift to the right and this will result in movement along the existing supply curve.",
  // },
  // {
  //   title: "MARKET FOR OIL",
  //   question: "Oil producers think the price of oil will increase in 6 months.",
  //   subQuestion: "Consider the market for oil. What happens?",
  //   icon: oil,
  //   answer: "Supply curve shifts left",
  //   graphType: "supply curve",
  //   briefness:
  //     "If oil providers think they can charge a higher price for oil in the near future, they will keep oil in the ground. The supply of oil will decrease, the supply curve will shift to the left, and that will result in movement along the existing demand curve.",
  // },

  // {
  //   title: "MARKET FOR SILVER",
  //   question: "The price of gold goes up.",
  //   subQuestion: "Consider the market for silver. What happens?",
  //   icon: silver,
  //   answer: "Demand curve shifts right",
  //   graphType: "supply curve",
  //   briefness:
  //     "Gold and silver are substitutes. If the price of gold increases, the quantity demanded will decrease. Demand for silver—a substitute—will increase. The demand curve for silver will shift to the right, resulting in movement along the existing supply curve.",
  // },

  // {
  //   title: "MARKET FOR VASES",
  //   question: "The price of flowers increases.",
  //   subQuestion: "Consider the market for vases. What happens?",
  //   icon: vase,
  //   answer: "Demand curve shifts left",
  //   graphType: "supply curve",
  //   briefness:
  //     "Flowers and vases are complements. As the price of flowers increases, the quantity demanded will decrease. Demand for vases—a complement—will decrease. The demand curve for vases will shift to the left and this will result in movement along the existing supply curve.",
  // },

  // {
  //   title: "MARKET FOR E-CIGARETTES",
  //   question: "State governments enact new tax on e-cigarette producers.",
  //   subQuestion: "Consider the market for e-cigarettes. What happens?",
  //   icon: eCigarettes,
  //   answer: "Supply curve shifts left",
  //   graphType: "supply curve",
  //   briefness:
  //     "Imposing a tax on suppliers of e-cigarettes is equivalent to increasing their production costs. The supply curve will shift to the left and this will result in movement along the existing demand curve.",
  // },

  // {
  //   title: "MARKET FOR CHAPSTICK",
  //   question: "Puppy adoptions surge during pandemic.",
  //   subQuestion: "Consider the market for chapstick. What happens?",
  //   icon: chapstick,
  //   answer: "Nothing happens",
  //   graphType: "supply curve",
  //   briefness:
  //     "This was a trick question. As far as we know there is no relationship between puppy adoptions and the market for chapstick.",
  // },

  // {
  //   title: "MARKET FOR AVOCADOS",
  //   question:
  //     "New farming technology makes it possible to grow avocados in more places.",
  //   subQuestion: "Consider the market for avocados. What happens?",
  //   icon: avocado,
  //   answer: "Supply curve shifts right",
  //   graphType: "supply curve",
  //   briefness:
  //     "If avocados can be grown in more locations, this increases the number of potential avocado sellers. The supply of avocados will increase, the supply curve will shift to the right, and that will result in movement along the existing demand curve.",
  // },

  // {
  //   title: "MARKET FOR LASER EYE SURGERY",
  //   question:
  //     "New law to allow optometrists to perform basic eye surgery by laser, a service previously performed only by those with more training.",
  //   subQuestion: "Consider the market for laser eye surgery. What happens?",
  //   icon: eyeSurgery,
  //   answer: "Supply curve shifts right",
  //   graphType: "supply curve",
  //   briefness:
  //     "If optometrists are now able to offer basic laser eye surgery, the supply of laser eye surgeries will increase. The supply curve for laser eye surgeries will shift to the right and this will result in movement along the existing demand curve.",
  // },

  // {
  //   title: "MARKET FOR GAMING CONSOLES",
  //   question:
  //     "LEAKED: New, more advanced game console to be launched in 6 months.",
  //   subQuestion: "Consider the market for gaming consoles. What happens?",
  //   icon: gaming,
  //   answer: "Demand curve shifts left",
  //   graphType: "supply curve",
  //   briefness:
  //     "If consumers think a new, superior gaming console will be sold in 6 months, they may hold off purchasing a new gaming console until then. Therefore, the demand for gaming consoles now will decrease and the demand curve will shift to the left. This will result in movement along the existing supply curve.",
  // },

  // {
  //   title: "MARKET FOR SODA",
  //   question: "The price of sugar—an ingredient in soda—reaches new heights.",
  //   subQuestion: "Consider the market for soda. What happens?",
  //   icon: soda,
  //   answer: "Supply curve shifts left",
  //   graphType: "supply curve",
  //   briefness:
  //     "If the price of sugar—an input for soda—increases, then the production costs of soda go up. The supply of soda will decrease and the supply curve will shift to the left. This will result in movement along the existing demand curve.",
  // },
];

export default questionAnswers;
