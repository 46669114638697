import { emulatorScreen, mobileScreen } from "utils/styleUtils";

const styles = (theme) => ({
  modalContainer: {
    backgroundColor: "#ecf2f1",
    height: "100%",
    padding: "3rem",
  },

  modalDescription: {
    color: "#003e4c",
    fontWeight: "400",
    fontFamily: "'Rubik', sans-serif",
    fontSize: "1.5rem",
    letterSpacing: "1px",
  },

  nextButton: {
    color: "#fff",
    backgroundColor: "#00b1d9",
    fontSize: "36px",
    fontWeight: "500",
    padding: "10px",
    borderRadius: "0px",
    fontFamily: "'Oswald', sans-serif",
    "&:hover": {
      backgroundColor: "#056f8a",
    },
  },
});
export default styles;
