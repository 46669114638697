import React from "react";
import Graph from "../../components/Graph";
import FirstQuestionSimulation from "../../components/FirstQuestionSimulation";

const FirstQuestionTutorialScreen = (props) => {
  return (
    <div
      style={{ height: "100%", minHeight: "100vh", backgroundColor: "#ecf2f1" }}
    >
      <FirstQuestionSimulation {...props} />
    </div>
  );
};

export default FirstQuestionTutorialScreen;
