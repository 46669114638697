import React from "react";
import { Modal, Grid, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import rotatePhone from "images/rotatePhone.svg";
import styles from "./styles";

const RotateModal = ({ isOpen, openPopup, setOpenPopup, classes }) => {
  console.log("openPopup", openPopup);
  return (
    <Modal open={isOpen && openPopup}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.modalContainer}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: 24 }}>
            This quiz is not formatted for mobile
          </Typography>
          <Button
            onClick={() => setOpenPopup(false)}
            className={classes.nextButton}
          >
            Ok
          </Button>
          {/* <img src={rotatePhone} width="200" /> */}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(RotateModal);
