import React, { useState, useEffect } from "react";
import correctIcon from "images/correct.svg";
import NotQuiteIcon from "images/notQuite.svg";
import IncorrectIcon from "images/incorrect.svg";
import { Grid, Typography } from "@material-ui/core";
import styles from "./styles";
import withStyles from "@material-ui/core/styles/withStyles";

const QuizAnswer = ({
  questionAnswer,
  classes,
  moved,
  supplyAnsweredCorrectly,
  demandAnsweredCorrectly,
  isSupplyMoved,
  isDemandMoved,
  secondStage,
  nextStageSubmitted,
  activePriceButton,
  activeQuantityButton,
  markSecondQuestion,
}) => {
  const [answerImage, setAnswerImage] = useState("");
  const [answerMessage, setAnswerMessage] = useState("");
  const [showBriefness, setShowBriefness] = useState(false);
  console.log("supplyAnsweredCorrectly", supplyAnsweredCorrectly);
  useEffect(() => {
    console.log("answers", supplyAnsweredCorrectly, demandAnsweredCorrectly);

    console.log("supply ", isSupplyMoved, " demand", isDemandMoved);
    whatwasMoved1();
  }, [moved]);

  useEffect(() => {
    if (nextStageSubmitted) {
      whatwasSelected();
    }
  }, [nextStageSubmitted]);

  const whatwasSelected = () => {
    let isPriceCorrectAnswer = false;
    let isQuantityCorrectAnswer = false;
    let correct = false;
    let inCorrect = false;
    let notQuite = false;
    if (activePriceButton.direction == questionAnswer.priceAnswer) {
      isPriceCorrectAnswer = true;
    }
    if (activeQuantityButton.direction == questionAnswer.quantityAnswer) {
      isQuantityCorrectAnswer = true;
    }

    if (
      activePriceButton.direction == questionAnswer.priceAnswer &&
      activeQuantityButton.direction == questionAnswer.quantityAnswer
    ) {
      setAnswerImage(correctIcon);
      setAnswerMessage("");
      correct == true;
      markSecondQuestion(true);
    } else if (
      activePriceButton.direction != questionAnswer.priceAnswer &&
      activeQuantityButton.direction != questionAnswer.quantityAnswer
    ) {
      setAnswerImage(IncorrectIcon);
      setAnswerMessage("");
      inCorrect == true;
      markSecondQuestion(false);
    } else {
      setAnswerImage(NotQuiteIcon);
      setAnswerMessage("");
      notQuite = true;
      markSecondQuestion(false);
    }

    // Questions: in second phase first questions (not quite message should be the same?) and some typo issue in other two not quite texts
    //question 2: what if student select on option like price but did not quantity? what should be the answer?
    if (notQuite) {
      if (isPriceCorrectAnswer) {
        if (questionAnswer.priceAnswer == "Up") {
          if (questionAnswer.quantityAnswer == "Up") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium price increases, but the new equilibrium quantity also increases. "
            );
          } else if (questionAnswer.quantityAnswer == "Down") {
          } else {
            setAnswerMessage(
              // Not quite! You're right that the new equilibrium price increases, but the new equilibrium quantity is indeterminate.
              "Not quite! You're right that the new equilibrium price increases, but the new equilibrium quantity is indeterminate. "
            );
          }
        } else if (questionAnswer.priceAnswer == "Down") {
          if (questionAnswer.quantityAnswer == "Up") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium price decreases, but the new equilibrium quantity increases. "
            );
          } else if (questionAnswer.quantityAnswer == "Down") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium price decreases, but the new equilibrium quantity also decreases."
            );
          } else {
          }
        } else {
          if (questionAnswer.quantityAnswer == "Up") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium price is indeterminate, but the new equilibrium quantity increases."
            );
          } else if (questionAnswer.quantityAnswer == "Down") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium price is indeterminate, but the new equilibrium quantity decreases. "
            );
          } else {
            setAnswerMessage("GGGG");
          }
        }
      }

      if (isQuantityCorrectAnswer) {
        if (questionAnswer.quantityAnswer == "Up") {
          if (questionAnswer.priceAnswer == "Up") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity increases, but the new equilibrium price also increases."
            );
          } else if (questionAnswer.priceAnswer == "Down") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity increases, but the new equilibrium price decreases."
            );
          } else {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity increases, but the new equilibrium price is indeterminate."
            );
          }
        } else if (questionAnswer.quantityAnswer == "Down") {
          if (questionAnswer.priceAnswer == "Up") {
          } else if (questionAnswer.priceAnswer == "Down") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity decreases, but the new equilibrium price also decreases."
            );
          } else {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity decreases, but the new equilibrium price is indeterminate."
            );
          }
        } else {
          if (questionAnswer.priceAnswer == "Up") {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity is indeterminate, but the new equilibrium price increases."
            );
          } else if (questionAnswer.priceAnswer == "Down") {
          } else {
            setAnswerMessage(
              "Not quite! You're right that the new equilibrium quantity increases, but the the new equilibrium price is indeterminate."
            );
          }
        }
      }
    }
  };

  const whatwasMoved1 = () => {
    let notQuite = false;

    if (supplyAnsweredCorrectly && demandAnsweredCorrectly) {
      setAnswerImage(correctIcon);
      setAnswerMessage("");
    } else if (!supplyAnsweredCorrectly && !demandAnsweredCorrectly) {
      setAnswerImage(IncorrectIcon);
      setAnswerMessage("");
    } else {
      setAnswerImage(NotQuiteIcon);
      notQuite = true;
      setShowBriefness(true);
    }

    if (notQuite) {
      if (supplyAnsweredCorrectly) {
        if (questionAnswer.supplyAnswer == "nothing") {
          if (questionAnswer.demandAnswer == "nothing") {
            setAnswerMessage("");
          } else {
            isDemandMoved
              ? setAnswerMessage(
                  // Not quite! You were right that the supply curve does not shift, but you shifted the demand curve in the wrong direction.
                  "Not quite! You were right that the supply curve did not shift, but you shifted the demand curve in the wrong direction."
                )
              : setAnswerMessage(
                  `Not quite! You were right not to shift the supply curve, but the demand curve ${questionAnswer.demandAnswer}.`
                );
          }
        } else {
          if (questionAnswer.demandAnswer == "nothing") {
            setAnswerMessage(
              "Not quite! You shifted the supply curve correctly, but the demand curve does not shift."
            );
          } else {
            setAnswerMessage(
              "Not quite! You shifted the supply curve correctly, but not the demand curve."
            );
          }
        }
      }

      if (demandAnsweredCorrectly) {
        if (questionAnswer.demandAnswer == "nothing") {
          if (questionAnswer.supplyAnswer == "nothing") {
            setAnswerMessage("");
          } else {
            isSupplyMoved
              ? setAnswerMessage(
                  "Not quite! You were right that the demand curve did not shift, but you shifted the supply curve in the wrong direction."
                )
              : setAnswerMessage(
                  `Not quite! You were right not to shift the demand curve, but the supply curve ${questionAnswer.supplyAnswer}.`
                );
          }
        } else {
          if (questionAnswer.supplyAnswer == "nothing") {
            setAnswerMessage(
              "Not quite! You shifted the demand curve correctly, but the supply curve does not shift."
            );
          } else {
            setAnswerMessage(
              "Not quite! You shifted the demand curve correctly, but not the supply curve."
            );
          }
        }
      }
    }
  };

  // const whatWasMoved = () => {
  //   if (supplyAnsweredCorrectly && demandAnsweredCorrectly) {
  //     setAnswerImage(correctIcon);
  //     setAnswerMessage("");
  //     return;
  //   }

  //   if (!supplyAnsweredCorrectly && !demandAnsweredCorrectly) {
  //     setAnswerImage(IncorrectIcon);
  //     setAnswerMessage("");
  //     return;
  //   }

  //   if (!supplyAnsweredCorrectly && demandAnsweredCorrectly) {
  //     setShowBriefness(true);
  //     setAnswerImage(NotQuiteIcon);
  //     if (
  //       questionAnswer.demandAnswer.includes("nothing") &&
  //       questionAnswer.supplyAnswer.includes("nothing")
  //     ) {
  //       setAnswerMessage(
  //         "Not quite! You were right not to shift the demand curve, but the supply curve shifts right."
  //       );
  //     } else if (questionAnswer.supplyAnswer.includes("nothing")) {
  //       isSupplyMoved &&
  //         setAnswerMessage(
  //           "Not quite! You shifted the demand curve correctly, but the supply curve does not shift."
  //         );
  //     } else if (questionAnswer.demandAnswer.includes("nothing")) {
  //       setAnswerMessage(
  //         "Not quite! You were right that the demand curve did not shift, but you shifted the supply curve in the wrong direction."
  //       );
  //     } else {
  //       setAnswerMessage(
  //         "Not quite! You shifted the demand curve correctly, but not the supply curve."
  //       );
  //     }
  //   }

  //   if (supplyAnsweredCorrectly && !demandAnsweredCorrectly) {
  //     setShowBriefness(true);
  //     setAnswerImage(NotQuiteIcon);
  //     if (
  //       questionAnswer.demandAnswer.includes("nothing") &&
  //       questionAnswer.supplyAnswer.includes("nothing")
  //     ) {
  //       setAnswerMessage(
  //         "Not quite! You were right not to shift the supply curve, but the demand curve shifts right."
  //       );
  //       // isSupplyMoved && Not quite! You shifted the demand curve correctly, but the supply curve does not shift.
  //       //need to discuss
  //       // setAnswerMessage();
  //     } else if (questionAnswer.demandAnswer.includes("nothing")) {
  //       //add by my self need to review by olivier
  //       !isDemandMoved &&
  //         setAnswerMessage(
  //           "Not quite! You shifted the supply curve correctly, but the demand curve does not shift."
  //         );
  //     } else if (questionAnswer.supplyAnswer.includes("nothing")) {
  //       setAnswerMessage(
  //         "Not quite! You were right that the supply curve does not shift, but you shifted the demand curve in the wrong direciton."
  //       );
  //     } else {
  //       setAnswerMessage(
  //         "Not quite! You shifted the supply curve correctly, but not the demand curve."
  //       );
  //     }
  //   }
  // };

  const answerPrefix = () => {
    if (questionAnswer.graphType === "supply curve") {
      return (
        !questionAnswer.answer.includes("Nothing") &&
        (questionAnswer.answer.includes("shifts")
          ? "Supply curve "
          : "Quantity supplied ")
      );
    } else {
      return (
        !questionAnswer.answer.includes("Nothing") &&
        (questionAnswer.answer.includes("shifts")
          ? "Demand curve "
          : "Quantity demanded ")
      );
    }
  };

  const firstAnswerPart = () => {
    return (
      <Grid className={classes.answerContainer}>
        <Typography variant="h5" className={classes.QuestionTextAgain}>
          Q. {questionAnswer.question}
          <br />
          {questionAnswer.subQuestion}
        </Typography>
        <img src={answerImage} className={classes.correctIncorrectIcon} />
        {/* <Typography>
      Supply Answered: {supplyAnsweredCorrectly ? "Correct" : "Wrong"}
    </Typography>
    <Typography>
      Demand Answered: {demandAnsweredCorrectly ? "Correct" : "Wrong"}
    </Typography> */}
        <Typography variant="h4" className={classes.curveShiftingText}>
          {/* {answerPrefix()} */}
          {questionAnswer.answer}
        </Typography>
        {showBriefness && (
          <Typography variant="h5" className={classes.briefNessHeading}>
            {answerMessage}
          </Typography>
        )}
        <Typography variant="h5" className={classes.briefNess}>
          {questionAnswer.briefness}
        </Typography>
      </Grid>
    );
  };

  const secondAnswerPart = () => {
    return (
      <Grid className={classes.answerContainer}>
        <Typography variant="h5" className={classes.QuestionTextAgain}>
          Q. {questionAnswer.secondSubQuestion}
        </Typography>
        <img src={answerImage} className={classes.correctIncorrectIcon} />
        {/* <Typography>
      Supply Answered: {supplyAnsweredCorrectly ? "Correct" : "Wrong"}
    </Typography>
    <Typography>
      Demand Answered: {demandAnsweredCorrectly ? "Correct" : "Wrong"}
    </Typography> */}
        <Typography variant="h4" className={classes.curveShiftingText}>
          {/* {answerPrefix()} */}
          {questionAnswer.answer}
        </Typography>
        {(showBriefness || nextStageSubmitted) && (
          <Typography variant="h5" className={classes.briefNessHeading}>
            {answerMessage}
          </Typography>
        )}
        <Typography variant="h5" className={classes.briefNess}>
          {nextStageSubmitted
            ? questionAnswer.secondBriefness
            : questionAnswer.briefness}
        </Typography>
      </Grid>
    );
  };

  return secondStage ? secondAnswerPart() : firstAnswerPart();
};

export default withStyles(styles)(QuizAnswer);
