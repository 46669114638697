import { emulatorScreen, mobileScreen } from "utils/styleUtils";

const styles = (theme) => ({
  verticalLinesContainer: {
    position: "absolute",
    top: "-30px",
    left: "210px",
    transition: "left 1s",
    [mobileScreen(theme)]: {
      top: "-20px",
    },
  },

  defaultLine: {
    transform: "rotate(45deg)",
    position: "absolute",
    height: "400px",
    width: "8px",
    backgroundColor: "#003e4c",
    borderRadius: "5px",
  },

  correctLine: {
    transform: "rotate(45deg)",
    position: "absolute",
    height: "400px",
    width: "8px",
    borderRadius: "5px",
  },

  dragableLine: {
    display: "flex",
    justifyContent: "center",
    height: "400px",
    width: "8px",
    position: "relative",
    borderRadius: "5px",
    transform: "rotate(45deg)",
    [emulatorScreen(theme)]: {
      width: "5px",
      height: "200px",
      borderWidth: "3px",
    },
    [mobileScreen(theme)]: {
      height: "150px",
    },
  },

  crossMark: {
    position: "absolute",
    borderRadius: "50%",
    transform: "rotate(45deg)",
    width: "35px",
    height: "35px",
    [emulatorScreen(theme)]: {
      width: "25px",
      height: "30px",
    },
  },

  handCursorDotDesign: {
    position: "absolute",
    right: "-38px",
    top: "200px",
    transform: "rotate(-45deg)",
    width: "40px",
    transition: "top 1s",
    [emulatorScreen(theme)]: {
      width: "25px",
      right: "-22px",
      top: "140px",
    },
    [mobileScreen(theme)]: {
      top: "110px",
    },
  },

  lineIcon: {
    position: "absolute",
    height: "25px",
    top: "-45px",
    left: "4px",
    transform: "rotate(-45deg)",
    [emulatorScreen(theme)]: {
      height: "20px",
      top: "-30px",
      left: "0",
    },
    [mobileScreen(theme)]: {
      height: "17px",
      top: "-28px",
      left: "4px",
    },
  },

  handCursorLineDesign: {
    position: "absolute",
    right: "-38px",
    top: "200px",
    transform: "rotate(-45deg)",
    width: "40px",
    transition: "top 1s",
    [emulatorScreen(theme)]: {
      width: "25px",
      right: "-22px",
      top: "140px",
    },
    [mobileScreen(theme)]: {
      top: "110px",
    },
  },
});
export default styles;
