import React, { useState, useEffect } from "react";
import BreakingNews from "./BreakingNews";
import QuizAnswer from "./QuizAnswer";

const QuizQuestion = (props) => {
  const [activePriceButton, setActivePriceButton] = useState({
    direction: "",
  });
  const [activeQuantityButton, setActiveQuantityButton] = useState({
    direction: "",
  });
  const {
    answeredCorrectly,
    supplyAnsweredCorrectly,
    demandAnsweredCorrectly,
    questionAnswer,
    moved,
    isDemandMoved,
    isSupplyMoved,
    nextStageSubmitted,
    secondStage,
    questionIndex,
    showTutorial,
    markSecondQuestion,
  } = props;

  if (
    answeredCorrectly === null &&
    supplyAnsweredCorrectly === null &&
    demandAnsweredCorrectly === null
  )
    return <BreakingNews questionAnswer={questionAnswer} />;
  if (questionIndex == 0 && showTutorial) {
    return (
      <BreakingNews showTutorial={showTutorial} questionIndex={questionIndex} />
    );
  }
  if (secondStage && !nextStageSubmitted) {
    return (
      <BreakingNews
        questionAnswer={questionAnswer}
        secondStage={secondStage}
        nextStageSubmitted={nextStageSubmitted}
        activePriceButton={activePriceButton}
        setActivePriceButton={setActivePriceButton}
        activeQuantityButton={activeQuantityButton}
        setActiveQuantityButton={setActiveQuantityButton}
      />
    );
  }

  console.log(
    "activePriceButton, activeQuantityButton",
    activePriceButton,
    activeQuantityButton
  );

  return (
    <QuizAnswer
      questionAnswer={questionAnswer}
      moved={moved}
      isDemandMoved={isDemandMoved}
      isSupplyMoved={isSupplyMoved}
      supplyAnsweredCorrectly={supplyAnsweredCorrectly}
      demandAnsweredCorrectly={demandAnsweredCorrectly}
      showBriefness={answeredCorrectly ? false : true}
      secondStage={secondStage}
      nextStageSubmitted={nextStageSubmitted}
      activePriceButton={activePriceButton}
      markSecondQuestion={markSecondQuestion}
      activeQuantityButton={activeQuantityButton}
    />
  );
};

export default QuizQuestion;
