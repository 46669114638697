import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import styles from "./styles";
import GraphLineDot from "./GraphLineDot";
import QuizQuestion from "./QuizQuestion";
import FirstQuestionSimulation from "../FirstQuestionSimulation";

const Graph = (props) => {
  const {
    classes,
    setAnswers,
    setPhase2Answers,
    questionAnswer,
    nextQuestion,
    resetGraph,
    modalOpen,
    setShowTutorial,
    showTutorial,
    questionIndex,
  } = props;

  const [answeredCorrectly, setAnsweredCorrectly] = useState(null);
  const [demandAnsweredCorrectly, setDemandAnsweredCorrectly] = useState(null);
  const [supplyAnsweredCorrectly, setSupplyAnsweredCorrectly] = useState(null);
  const [moved, setMoved] = useState(null);
  const [isSupplyMoved, setIsSupplyMoved] = useState(false);
  const [isDemandMoved, setIsDemandMoved] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [secondStage, setSecondStage] = useState(false);
  const [nextStageSubmitted, setNextStageSubmitted] = useState(false);
  const [dottedLineLabels, setDottedLineLabels] = useState(false);
  // const [showTutorial, setShowTutorial] = useState(false);
  const [hideQuestionBeforeAnimation, setHideQuestionBeforeAnimation] =
    useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHideQuestionBeforeAnimation(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (
      (supplyAnsweredCorrectly !== null && demandAnsweredCorrectly !== null) ||
      (supplyAnsweredCorrectly !== null && demandAnsweredCorrectly == null) ||
      (supplyAnsweredCorrectly == null && demandAnsweredCorrectly !== null)
    )
      setAnswers((prevAnswers) => {
        console.log("oldone", prevAnswers);
        return [
          ...prevAnswers,
          supplyAnsweredCorrectly & demandAnsweredCorrectly,
        ];
      });
  }, [moved]);

  const markSecondQuestion = (isCorrect) => {
    setPhase2Answers((prevPhase2Answers) => {
      console.log("hello", prevPhase2Answers);
      return [...prevPhase2Answers, isCorrect];
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    // questionAnswer.answer.includes('goes') && setTimeout(() => setDottedLineLabels(true), 1000)
  };

  const setTutorial = () => {
    setShowTutorial(true);
    // questionAnswer.answer.includes('goes') && setTimeout(() => setDottedLineLabels(true), 1000)
  };

  const nextStage = () => {
    setShowTutorial(false);
    setSecondStage(true);
    // questionAnswer.answer.includes('goes') && setTimeout(() => setDottedLineLabels(true), 1000)
  };

  const stageSubmitted = () => {
    setNextStageSubmitted(true);
  };

  console.log("hello questionIndex: ", questionIndex);

  return (
    <Grid container alignItems="center" direction="column">
      <Grid container item justify="center" alignItems="center">
        <Grid
          style={{
            position: "relative",
            display: "flex",
            alignSelf: "flex-start",
          }}
        >
          {/* {showTutorial && questionIndex == 0 ? (
            <FirstQuestionSimulation {...props} nextStage={nextStage} />
          ) : (
            <> */}
          <GraphLineDot
            questionAnswer={questionAnswer}
            setAnsweredCorrectly={setAnsweredCorrectly}
            answeredCorrectly={answeredCorrectly}
            setSupplyAnsweredCorrectly={setSupplyAnsweredCorrectly}
            setDemandAnsweredCorrectly={setDemandAnsweredCorrectly}
            supplyAnsweredCorrectly={supplyAnsweredCorrectly}
            demandAnsweredCorrectly={demandAnsweredCorrectly}
            setMoved={setMoved}
            setIsSupplyMoved={setIsSupplyMoved}
            setIsDemandMoved={setIsDemandMoved}
            submitted={submitted}
            key={resetGraph}
            nextStageSubmitted={nextStageSubmitted}
            secondStage={secondStage}
          />
          {!secondStage ? (
            <Button
              onClick={
                submitted ? nextStage : handleSubmit
                // submitted
                //   ? !showTutorial && questionIndex == 0
                //     ? setTutorial
                //     : nextStage
                //   : handleSubmit
              }
              className={submitted ? classes.nextButton : classes.submitButton}
            >
              {submitted ? "NEXT" : "SUBMIT"}
            </Button>
          ) : (
            <Button
              onClick={nextStageSubmitted ? nextQuestion : stageSubmitted}
              className={
                nextStageSubmitted ? classes.nextButton : classes.submitButton
              }
            >
              {nextStageSubmitted ? "NEXT" : "SUBMIT"}
            </Button>
          )}
          {/* </>
          )} */}
        </Grid>
        {hideQuestionBeforeAnimation ? (
          <Grid className={classes.questionContainerHidden} />
        ) : (
          <QuizQuestion
            answeredCorrectly={answeredCorrectly}
            supplyAnsweredCorrectly={supplyAnsweredCorrectly}
            demandAnsweredCorrectly={demandAnsweredCorrectly}
            questionAnswer={questionAnswer}
            moved={moved}
            isSupplyMoved={isSupplyMoved}
            isDemandMoved={isDemandMoved}
            nextStageSubmitted={nextStageSubmitted}
            secondStage={secondStage}
            questionIndex={questionIndex}
            showTutorial={showTutorial}
            markSecondQuestion={markSecondQuestion}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Graph);
