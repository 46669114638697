import React from "react";
import styles from "./styles";
import breakingNews from "images/breakingnews.svg";
import ArrowDown from "images/downCaret.svg";
import ArrowUp from "images/upCaret.svg";
import ArrowUpWhite from "images/upWhiteCaret.svg";
import ArrowDownWhite from "images/downWhiteCaret.svg";
import { Grid, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import "animate.css/animate.css";
import { useState } from "react";

const BreakingNews = ({
  questionAnswer,
  secondStage,
  nextStageSubmitted,
  classes,
  questionIndex,
  showTutorial,
  activePriceButton,
  setActivePriceButton,
  activeQuantityButton,
  setActiveQuantityButton,
}) => {
  // const [activePriceButton, setActivePriceButton] = useState({
  //   direction: "",
  // });
  // const [activeQuantityButton, setActiveQuantityButton] = useState({
  //   direction: "",
  // });

  const getIcon = (direction, type) => {
    return direction === "Up"
      ? (type === "PRICE" && activePriceButton.direction === direction) ||
        (type === "QUANTITY" && activeQuantityButton.direction === direction)
        ? ArrowUpWhite
        : ArrowUp
      : (type === "PRICE" && activePriceButton.direction === direction) ||
        (type === "QUANTITY" && activeQuantityButton.direction === direction)
      ? ArrowDownWhite
      : ArrowDown;
  };

  const getButton = (type, direction) => {
    return (
      <Grid
        item
        xs={12}
        container
        justify="center"
        className={
          type === "PRICE"
            ? activePriceButton.direction === direction
              ? classes.activeQuestionButton
              : classes.questionButton
            : activeQuantityButton.direction === direction
            ? classes.activeQuestionButton
            : classes.questionButton
        }
        onClick={() => {
          type === "PRICE"
            ? setActivePriceButton({
                direction: direction,
              })
            : setActiveQuantityButton({
                direction: direction,
              });
        }}
      >
        {direction !== "Indeterminate" ? (
          <img src={getIcon(direction, type)} className={classes.buttonIcon} />
        ) : (
          <Typography className={classes.buttonText}>{direction}</Typography>
        )}
      </Grid>
    );
  };

  const getButtons = (title) => {
    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4} alignItems="center" container>
          <Typography variant="h5" className={classes.newQuestionTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} alignItems="center" container>
          {getButton(title, "Up")}
        </Grid>
        <Grid item xs={2} alignItems="center" container>
          {getButton(title, "Down")}
        </Grid>
        <Grid item xs={4} alignItems="center" container>
          {getButton(title, "Indeterminate")}
        </Grid>
      </Grid>
    );
  };

  const firstQuestion = () => {
    return (
      <Grid
        className={`${classes.questionContainer} animate__animated animate__bounceIn`}
      >
        <Grid className={classes.questionBody}>
          <img src={breakingNews} className={classes.breakingNews}></img>
          <img src={questionAnswer.icon} className={classes.circleIcon}></img>

          <Typography variant="h5" className={classes.questionText}>
            {questionAnswer.question}
          </Typography>
          <Typography variant="h5" className={classes.subQuestionText}>
            {questionAnswer.subQuestion}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const secondQuestion = () => {
    return (
      <Grid
        className={`${classes.container} animate__animated animate__bounceIn`}
      >
        {/* <Grid item xs={12}>
          <Typography>
            <Typography variant="h5" className={classes.newQuestionTitle}>
              Given that you don't know the magnitude of the shifts...
            </Typography>
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography>
            <Typography
              variant="h5"
              className={classes.newQuestionTitle}
              // style={{ fontSize: 36 }}
            >
              {questionAnswer.secondQuestion}
              {/* What happens to the equilibrium... */}
            </Typography>
          </Typography>
        </Grid>
        {getButtons("PRICE")}
        {getButtons("QUANTITY")}
      </Grid>
    );
  };

  const tutorialQuestion = () => {
    return (
      <Grid
        className={`${classes.tutorialContainer} animate__animated animate__bounceIn`}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className={classes.newQuestionTitle}
            style={{ fontSize: 36, paddingBottom: 0 }}
          >
            HINT:
          </Typography>
          <Typography
            variant="h4"
            className={classes.newQuestionTitle}
            style={{ fontSize: 36, paddingTop: 0 }}
          >
            You don't know the magnitude of the shifts
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return questionIndex == 0 && showTutorial
    ? tutorialQuestion()
    : secondStage
    ? secondQuestion()
    : firstQuestion();

  // <Grid
  //   className={`${classes.questionContainer} animate__animated animate__bounceIn`}
  // >
  //   <Grid className={classes.questionBody}>
  //     <img src={breakingNews} className={classes.breakingNews}></img>
  //     <img src={questionAnswer.icon} className={classes.circleIcon}></img>

  //     <Typography variant="h5" className={classes.questionText}>
  //       {questionAnswer.question}
  //     </Typography>
  //     <Typography variant="h5" className={classes.subQuestionText}>
  //       {questionAnswer.subQuestion}
  //     </Typography>
  //   </Grid>
  // </Grid>
};

export default withStyles(styles)(BreakingNews);
