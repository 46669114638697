import React, { useState, useEffect } from "react";
import Draggable from "react-draggable"; // Both at the same time
import { withStyles } from "@material-ui/core/styles";
import sBlue from "images/equalibriumIcons/S-blue.svg";
import S1 from "images/equalibriumIcons/S1.svg";
import S2 from "images/equalibriumIcons/S2.svg";
import HandCursor from "images/handCursor.svg";
import xMark from "images/xMark.svg";
import "animate.css/animate.css";
import styles from "./styles";

const LineDotRight = (props) => {
  const { classes, move, lineDefaultPosition, lineShiftVariant, moveableLine } =
    props;

  //set line default position
  const [linePosition, setLinePosition] = useState(`${lineDefaultPosition}px`);

  useEffect(() => {
    switch (move) {
      case "none":
        break;
      case "line":
        animateLine();
        break;
      default:
        break;
    }
  }, [move]);

  const animateLine = () => {
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition + lineShiftVariant}px`);
    }, 1000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition - lineShiftVariant}px`);
      // animateLine();
    }, 2000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition + lineShiftVariant}px`);
    }, 3000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition - lineShiftVariant}px`);
      // animateLine();
    }, 4000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition}px`);
    }, 5000);
  };

  return (
    <div
      className={classes.verticalLinesContainer}
      style={{
        left: move === "line" ? linePosition : `${lineDefaultPosition}px`,
      }}
    >
      <div>
        <div
          className={classes.dragableLine}
          style={{ backgroundColor: moveableLine ? "#508a05" : "#00b1d9" }}
        >
          {moveableLine ? (
            <img src={S2} className={classes.lineIcon} />
          ) : (
            <img src={S1} className={classes.lineIcon} />
          )}
          <div style={{ height: "40px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(LineDotRight);
