import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import SupplyCurveHandler from "./SupplyCurveHandler";
import DemandCurveHandler from "./DemandCurveHandler";
import { Grid, Typography, Button, Modal } from "@material-ui/core";
import youCan from "../../images/youCan.svg";
import shiftTheCurve from "../../images/double_line_icons/tutorial-shift-both-curves.svg";
import price from "../../images/price.svg";
import quantity from "../../images/quantity.svg";
import dragLine from "../../images/dragLine.svg";
import dragLineRight from "../../images/dragLineRight.svg";
import { isMobileOnly } from "react-device-detect";
import "animate.css/animate.css";

const FirstQuestionSimulation = (props) => {
  const { classes, setCurrentScreen, nextStage } = props;

  const [move, setMove] = useState("none");
  const [quizButton, setQuizButton] = useState("skip");
  const [showYouCanText, setShowYouCanText] = useState(false);
  const [showShiftText, setShowShiftText] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);

  const [runTutorial, setRunTutorial] = useState("none");

  useEffect(() => {
    setTimeout(() => {
      setShowYouCanText(true);
    }, 1000);
    setTimeout(() => {
      setShowShiftText(true);
    }, 2000);
    setTimeout(() => {
      setShowGraphs(true);
    }, 2000);
    if (isMobileOnly) setTimeout(onClickSkip, 5500);
  }, []);

  return (
    <Grid
      className={classes.quizContainer}
      // container
      // item
      // direction="column"
      // alignItems="center"
      onClick={nextStage}
      // style={{ cursor: "pointer" }}
    >
      {/* <Grid className={classes.tutorialGrid}>
        <Typography className={classes.tutorialText}>TUTORIAL</Typography>
      </Grid> */}
      {/* {showYouCanText && (
        <Grid
          container
          item
          justify="center"
          className="animate__animated animate__bounceIn"
        >
          <img src={youCan} className={classes.youCanImg} />
        </Grid>
      )} */}
      <Typography
        variant="h5"
        align="center"
        className={`${classes.questionTitle}`}
      >
        MARKET FOR HONEY
      </Typography>
      <Grid
        container
        item
        justify="center"
        className={[classes.graphContainer]}
      >
        <Grid
          container
          className={classes.graphDivContainer}
          style={{ marginRight: "105px" }}
        >
          <div className={classes.graphDiv}>
            <Grid container justify="center" style={{ maxWidth: "520px" }}>
              <Grid className={classes.graphLines}>
                {/* <img src={dragLine} className={classes.dragLineMessage} /> */}
                {/* <img
                      src={dragLineRight}
                      className={classes.dragLineRightMessage}
                    /> */}
                <img src={price} className={classes.graphYLable} />
                {/* <LineDotLeftHandler move={"line"} /> */}
                {/* <LineDotRightHandler move={"line"} /> */}
                <SupplyCurveHandler
                  move={"line"}
                  variant={50}
                  left={190}
                  moveableLine={true}
                />
                <SupplyCurveHandler move={"line"} variant={0} left={250} />

                <DemandCurveHandler
                  move={"line"}
                  variant={50}
                  moveableLine={true}
                  left={310}
                />
                <DemandCurveHandler move={"line"} variant={0} left={250} />
                <img src={quantity} className={classes.graphXLable} />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Button className={classes.submitButton} onClick={nextStage}>
        GOT IT
      </Button>
    </Grid>
  );
};

export default withStyles(styles)(FirstQuestionSimulation);
