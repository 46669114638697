import React, { useState, useEffect, useLayoutEffect } from "react";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { withStyles } from "@material-ui/core/styles";
import leftArrow from "images/leftArrow";
import rightArrow from "images/rightArrow";
import dGreen from "images/equalibriumIcons/d-green.svg";
import dBlue from "images/equalibriumIcons/D-blue.svg";
import D1 from "images/equalibriumIcons/D1.svg";
import D2 from "images/equalibriumIcons/D2.svg";
import styles from "../demandStyles";
import "animate.css/animate.css";

const DemandCurve = (props) => {
  const { classes } = props;

  const {
    questionAnswer,
    answeredCorrectly,
    setAnsweredCorrectly,
    setSupplyAnsweredCorrectly,
    setDemandAnsweredCorrectly,
    supplyAnsweredCorrectly,
    demandAnsweredCorrectly,
    setMoved,
    submitted,
    supplyMoved,
    setSupplyMoved,
    demandMoved,
    setDemandMoved,
    modalOpen,
    setShowDragMessage,
    setIsDemandMoved,
    nextStageSubmitted,
    secondStage,
  } = props;

  //adjust dot center accoring to line
  const heightOfLine = 400;
  const dotCenterPosition = (heightOfLine - 30) / 2;

  const { demandAnswer, supplyAnswer } = questionAnswer;
  const answer = demandAnswer;
  const [linePosition, setLinePosition] = useState({ x: 0, y: 0 });
  const [correctPosition, setCorrectPosition] = useState(0);
  const [wrongPosition, setWrongPosition] = useState(null);
  const [showLine, setShowLine] = useState(false);
  const [showDot, setShowDot] = useState(false);
  const [changeIconColor, setChangeIconColor] = useState(false);
  const [lineColor, setLineColor] = useState("#003E4C");
  const [arrowIcon, setArrowIcon] = useState();
  const [arrowFadeIn, setArrowFadeIn] = useState();
  const [arrowPosition, setArrowPosition] = useState({ left: 0, top: 0 });
  const [showDottedLines, setShowDottedLines] = useState(false);
  const [reRender, setReRender] = useState(false);
  // animate__fadeIn
  useEffect(() => {
    if (submitted) {
      if (answer.includes("nothing")) {
        if (nothingMove()) {
          setDemandAnsweredCorrectly(true);
          setMoved("correct");
        } else {
          setColors();
          nothingAsWrong();
        }
      } else if (nothingMove()) {
        nothingAsWrong();
      } else {
        evaluateLineAnswer(linePosition.x);
      }
      showArrow();
      answer.includes("shifts") && setChangeIconColor(true);
      // setTimeout(() => {
      //   if (answer.includes("Demand curve")) {
      //     setShowDottedLines(true);
      //     setShowDot(true);
      //   }
      // }, 1000);
    }
  }, [submitted]);

  useEffect(() => {
    if (secondStage) {
      setDemandAnsweredCorrectly(true);
      // setChangeIconColor(true);
      lineCorrectPostiton();

      // setSupplyAnsweredCorrectly(true);
    }
  }, [secondStage]);

  useEffect(() => {
    if (nextStageSubmitted) {
      setShowDot(true);
    }
  }, [nextStageSubmitted]);

  const getDotPosition = () => {
    if (demandAnswer == "shifts right" && supplyAnswer == "shifts right") {
      return 185;
    } else if (
      demandAnswer == "shifts right" &&
      supplyAnswer == "shifts left"
    ) {
      return 84;
    } else if (demandAnswer == "shifts right" && supplyAnswer == "nothing") {
      return 137;
    } else if (
      demandAnswer == "shifts left" &&
      supplyAnswer == "shifts right"
    ) {
      return 283;
    } else if (demandAnswer == "shifts left" && supplyAnswer == "shifts left") {
      return 184;
    } else if (demandAnswer == "shifts left" && supplyAnswer == "nothing") {
      return 234;
    } else if (demandAnswer == "nothing" && supplyAnswer == "shifts right") {
      return 234;
    } else if (demandAnswer == "nothing" && supplyAnswer == "shifts left") {
      return 135;
    } else if (demandAnswer == "nothing" && supplyAnswer == "nothing") {
      return 184;
    }
    return dotCenterPosition;
  };

  const getFixedDot = () => {
    if (demandAnswer == "shifts left") {
      return 234;
    }
    return 135;
  };

  const getFixedDotLeft = () => {
    if (demandAnswer == "shifts left") {
      return 42;
    }
    if (demandAnswer == "shifts right") {
      return -58;
    }
    if (demandAnswer == "nothing" && supplyAnswer == "nothing") return 135;
    return -10;
  };

  useEffect(() => {
    // if (answeredCorrectly === false && demandMoved) {
    //   switch(answer) {
    //     case "Demand curve shifts left":
    //       setShowLine(true)
    //       setCorrectPosition(-70)
    //       break;
    //     case "Demand curve shifts right":
    //       setShowLine(true)
    //       setCorrectPosition(70)
    //       break;
    //   }
    //   setDemandAnsweredCorrectly(false)
    //   return
    // }
  }, [answeredCorrectly]);

  window.addEventListener("resize", () =>
    setTimeout(() => {
      setReRender(!reRender);
      return window.removeEventListener("resize", () => {});
    }, 250)
  );

  const showArrow = () => {
    switch (answer) {
      case "shifts left":
        setArrowIcon(leftArrow);
        setArrowPosition({ top: dotCenterPosition - 60, left: -110 });
        break;
      case "shifts right":
        setArrowPosition({ top: dotCenterPosition + 55, left: 75 });
        setArrowIcon(rightArrow);
        break;
    }
    setArrowFadeIn(
      "animate__animated animate__fadeIn animate__slow animate__delay-1s"
    );
  };

  const DragStartLine = (event) => {};

  const DragLine = (event, ui) => {
    setLinePosition({
      x: linePosition.x + ui.deltaX,
      y: linePosition.y + ui.deltaY,
    });
  };

  const nothingMove = () => !demandMoved;

  const setColors = () => {
    if (!demandAnsweredCorrectly) setLineColor("red");
  };

  const nothingAsWrong = () => {
    switch (answer) {
      case "shifts left":
        setShowLine(true);
        setCorrectPosition(-70);
        break;
      case "shifts right":
        setShowLine(true);
        setCorrectPosition(70);
        break;
    }
    setMoved("nothing");
    setDemandAnsweredCorrectly(false);
  };

  const markQuestionAsWrong = () => {
    switch (answer) {
      case "shifts left":
        setShowLine(true);
        setCorrectPosition(-70);
        setMoved("moved demand curve but in opposite");
        setColors();
        break;
      case "shifts right":
        setShowLine(true);
        setCorrectPosition(70);
        setMoved("moved demand curve but in opposite");
        setColors();
        break;
      default:
        setColors();
        // answer.includes("Supply curve shifts") &&
        setMoved("incorrect");
    }
    setDemandAnsweredCorrectly(false);
  };

  const lineCorrectPostiton = () => {
    switch (answer) {
      case "shifts left":
        setLinePosition({ x: -70, y: 0 });
        break;
      case "shifts right":
        setLinePosition({ x: 70, y: 0 });
        break;
      default:
        setLinePosition({ x: 0, y: 0 });
    }
  };

  const DragEndLine = (event) => {
    if (linePosition.x <= 20 && linePosition.x >= -20) {
      setLinePosition({ x: 0, y: 0 });
      setDemandMoved(false);
      setIsDemandMoved(false);
    } else {
      setDemandMoved(true);
      setIsDemandMoved(false);
    }
  };

  const evaluateLineAnswer = (value) => {
    // if (value <= 10 && value >= -10)
    //   return

    let answerMatched = false;

    if (value > 10) {
      // setLinePosition({x: 75, y: 0})
      answerMatched = answer === "shifts right";
    } else if (value < -10) {
      // setLinePosition({x: -75, y: 0})
      answerMatched = answer === "shifts left";
    }

    answerMatched ? markQuestionAsCorrect() : markQuestionAsWrong();
  };

  const markQuestionAsCorrect = () => {
    setAnsweredCorrectly(true);
    changePosition();
    setDemandAnsweredCorrectly(true);
    setMoved("correct");
  };
  const changePosition = () => {
    switch (answer) {
      case "shifts left":
        setLinePosition({ x: -70, y: 0 });
        break;
      case "shifts right":
        setLinePosition({ x: 70, y: 0 });
        break;
      default:
      // code block
    }
  };

  const draggableLineColor = () => {
    if (demandAnsweredCorrectly && answer.includes("shifts")) {
      return "#508a05";
    }
    if (supplyAnsweredCorrectly && answer.includes("nothing")) {
      return;
    }
    return wrongPosition ? "#003E4C" : lineColor;
  };

  const lineMovedOrNotAnswered = () => {
    if (demandAnsweredCorrectly === null) return true;
    if (demandAnsweredCorrectly === false) return false;
    // if (answer.includes("Supply curve")) return true;
    if (linePosition.x !== 0 && demandAnsweredCorrectly) return true;

    return false;
  };

  const showIconDefaultLine = () => {
    if (answer.includes("nothing")) return dBlue;
    return D1;
  };

  const p2ToOrigin = () => dotCenterPosition - 34;
  const p1ToCorrect = () =>
    answer.includes("shifts right")
      ? dotCenterPosition - 70
      : dotCenterPosition;
  const q2ToCorrect = () =>
    answer.includes("shifts right") ? "265px" : "195px";
  const q1ToOrigin = () => "230px";
  const qTop = () => "330px";

  return (
    <div>
      <div
        className={classes.verticalLinesContainer}
        style={{
          zIndex:
            answeredCorrectly !== null && answer.includes("shifts") ? "1" : "0",
        }}
      >
        <div className={arrowFadeIn}>
          <img
            src={arrowIcon}
            className={classes.arrows}
            style={{ top: arrowPosition.top, left: arrowPosition.left }}
          />
        </div>
        <div className={classes.defaultLine}>
          {!(demandAnsweredCorrectly === null) && (
            <img src={showIconDefaultLine()} className={classes.lineIcon} />
          )}
        </div>

        {
          //correct line
        }
        <div
          className={classes.correctLine}
          style={{
            zIndex: showLine ? "1" : "-1",
            backgroundColor: wrongPosition ? lineColor : "#508a05",
            transition: `left ${wrongPosition ? "0s" : "1s"}`,
            left: wrongPosition ? wrongPosition : correctPosition,
            opacity: lineColor == "red" && wrongPosition ? 0.7 : 1,
          }}
        >
          {/* {showDot && (
            <div
              className={classes.originalFadedDot}
              style={{
                opacity: "1",
                left: `${getFixedDotLeft()}px`,
                top: `${getFixedDot()}px`,
              }}
            />
          )} */}
          {changeIconColor && !demandAnsweredCorrectly && (
            <img
              src={D2}
              className={
                changeIconColor && !demandAnsweredCorrectly
                  ? classes.correctLineIcon
                  : classes.lineIcon
              }
            />
          )}
        </div>
        <Draggable
          axis="x"
          defaultPosition={{ x: 0, y: 0 }}
          position={linePosition}
          scale={1}
          bounds={{ top: 0, left: -70, right: 70, bottom: 0 }}
          onStart={DragStartLine}
          onDrag={DragLine}
          onStop={DragEndLine}
          disabled={secondStage}
        >
          <div style={{ cursor: !demandMoved && "pointer" }}>
            <div
              className={classes.dragableLine}
              id="draggable_line"
              style={{
                backgroundColor: draggableLineColor(),
                opacity: draggableLineColor() == "red" ? 0.7 : 1,
              }}
            >
              {console.log("fghh", draggableLineColor())}
              {showDot && (
                <div
                  className={`${classes.correctFadedDot} animate__animated animate__bounceIn`}
                  style={{
                    opacity: "1",
                    zIndex: "1",
                    top: getDotPosition(),
                  }}
                />
              )}
              {lineMovedOrNotAnswered() && (
                <img
                  src={changeIconColor && demandAnsweredCorrectly ? D2 : dBlue}
                  className={
                    changeIconColor && demandAnsweredCorrectly
                      ? classes.correctLineIcon
                      : classes.lineIcon
                  }
                />
              )}
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default withStyles(styles)(DemandCurve);
