import React, { useState } from "react";
import RouterScreen from "../RouterScreen";
import { withOrientationChange } from "react-device-detect";
import RotateModal from "components/RotateModal";

const App = (props) => {
  const { isPortrait, isLandscape } = props;
  console.log("props: ", props);
  const [openPopup, setOpenPopup] = useState(true);
  return (
    <>
      <RouterScreen />
      {/* <RotateModal isOpen={isPortrait} /> */}
      <RotateModal
        isOpen={isPortrait || isLandscape}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </>
  );
};

export default withOrientationChange(App);
