import React, { useState, useEffect } from "react";
import Draggable from "react-draggable"; // Both at the same time
import dGreen from "images/equalibriumIcons/d-green.svg";
import D1 from "images/equalibriumIcons/D1.svg";
import D2 from "images/equalibriumIcons/D2.svg";
// import sBlue from 'images/equalibriumIcons/S-blue.svg'
import { withStyles } from "@material-ui/core/styles";
import HandCursor from "images/handCursor.svg";
import xMark from "images/xMark.svg";
import "animate.css/animate.css";
import styles from "./styles";

const SimulatorDemandCurve = (props) => {
  const {
    classes,
    move,
    dotCenterPosition,
    dotShiftVariant,
    lineDefaultPosition,
    lineShiftVariant,
    moveableLine,
  } = props;

  //adjust dot center accoring to line
  const [dotPosition, setDotPosition] = useState(dotCenterPosition);
  const [linePosition, setLinePosition] = useState(`${lineDefaultPosition}px`);

  useEffect(() => {
    switch (move) {
      case "none":
        break;
      case "line":
        animateLine();
        break;
      default:
        break;
    }
  }, [move]);

  const animateLine = () => {
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition - lineShiftVariant}px`);
    }, 1000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition + lineShiftVariant}px`);
      // animateLine();
    }, 2000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition - lineShiftVariant}px`);
    }, 3000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition + lineShiftVariant}px`);
      // animateLine();
    }, 4000);
    setTimeout(() => {
      setLinePosition(`${lineDefaultPosition}px`);
      // animateLine();
    }, 5000);
  };

  return (
    <div
      className={classes.verticalLinesContainer}
      style={{ left: linePosition }}
    >
      <div>
        <div
          className={classes.dragableLine}
          style={{ backgroundColor: moveableLine ? "#508a05" : "#003E4C" }}
        >
          <img src={moveableLine ? D2 : D1} className={classes.lineIcon} />
          <div style={{ height: "40px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SimulatorDemandCurve);
