import React, { useState, useEffect, useLayoutEffect } from "react";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { withStyles } from "@material-ui/core/styles";
import leftArrow from "images/leftArrow";
import rightArrow from "images/rightArrow";
import sGreen from "images/equalibriumIcons/s-green.svg";
import sBlue from "images/equalibriumIcons/S-blue.svg";
import S1 from "images/equalibriumIcons/S1.svg";
import S2 from "images/equalibriumIcons/S2.svg";
import styles from "../supplyStyles";
import "animate.css/animate.css";
import DemandCurve from "../DemandCurve";
import Labels from "../Labels";

const SupplyCurve = (props) => {
  const { classes } = props;

  const {
    questionAnswer,
    answeredCorrectly,
    setAnsweredCorrectly,
    setSupplyAnsweredCorrectly,
    setDemandAnsweredCorrectly,
    supplyAnsweredCorrectly,
    demandAnsweredCorrectly,
    setMoved,
    submitted,
    supplyMoved,
    setSupplyMoved,
    demandMoved,
    setDemandMoved,
    modalOpen,
    setIsSupplyMoved,
    nextStageSubmitted,
    secondStage,
  } = props;

  //adjust dot center accoring to line
  const heightOfLine = 400;
  const dotCenterPosition = (heightOfLine - 30) / 2;

  const { supplyAnswer } = questionAnswer;
  const answer = supplyAnswer;
  const [linePosition, setLinePosition] = useState({ x: 0, y: 0 });
  const [correctPosition, setCorrectPosition] = useState(0);
  const [wrongPosition, setWrongPosition] = useState(null);
  const [showLine, setShowLine] = useState(false);
  const [showDot, setShowDot] = useState(false);
  const [changeIconColor, setChangeIconColor] = useState(false);
  const [lineColor, setLineColor] = useState("#1db2d7");
  const [fadeDot, setFadeDot] = useState("1");
  const [arrowIcon, setArrowIcon] = useState();
  const [arrowFadeIn, setArrowFadeIn] = useState();
  const [arrowPosition, setArrowPosition] = useState({ left: 0, top: 0 });
  const [showDottedLines, setShowDottedLines] = useState(false);
  const [reRender, setReRender] = useState(false);
  // animate__fadeIn
  useEffect(() => {
    if (submitted) {
      if (answer.includes("nothing")) {
        if (nothingMove()) {
          setAnsweredCorrectly(true);
          setSupplyAnsweredCorrectly(true);
          setMoved("correct");
        } else {
          setColors();
          nothingAsWrong();
        }
      } else if (nothingMove()) {
        nothingAsWrong();
      } else {
        evaluateLineAnswer(linePosition.x);
      }
      showArrow();
      answer.includes("shifts") && setChangeIconColor(true);
      // setTimeout(() => {
      //   if (answer.includes("Supply curve")) {
      //     setShowDottedLines(true);
      //     setShowDot(true);
      //   }
      // }, 1000);
    }
  }, [submitted]);

  useEffect(() => {
    if (secondStage) {
      setSupplyAnsweredCorrectly(true);
      // setChangeIconColor(true);
      lineCorrectPostiton();

      // setSupplyAnsweredCorrectly(true);
    }
  }, [secondStage]);

  window.addEventListener("resize", () =>
    setTimeout(() => {
      setReRender(!reRender);
      return window.removeEventListener("resize", () => {});
    }, 250)
  );

  const showArrow = () => {
    switch (answer) {
      case "shifts left":
        setArrowIcon(leftArrow);
        setArrowPosition({ top: dotCenterPosition + 55, left: -100 });
        break;
      case "shifts right":
        setArrowPosition({ top: dotCenterPosition - 45, left: 70 });
        setArrowIcon(rightArrow);
        break;
    }
    setArrowFadeIn(
      "animate__animated animate__fadeIn animate__slow animate__delay-1s"
    );
  };

  const DragStartLine = (event) => {
    // if (supplyMoved) {
    console.log("supply line was dragged");
    // setLinePosition({ x: 0, y: 0 })
    // modalOpen()
    // }
  };

  const DragLine = (event, ui) => {
    // if (supplyMoved) {
    // console.log("supply line was dragged")
    // setLinePosition({x: 0, y: 0})
    // } else {
    setFadeDot(0.5);
    setLinePosition({
      x: linePosition.x + ui.deltaX,
      y: linePosition.y + ui.deltaY,
    });
    // }
  };

  const lineCorrectPostiton = () => {
    switch (answer) {
      case "shifts left":
        setLinePosition({ x: -70, y: 0 });
        break;
      case "shifts right":
        setLinePosition({ x: 70, y: 0 });
        break;
      default:
        setLinePosition({ x: 0, y: 0 });
    }
  };

  const DragEndLine = (event) => {
    if (linePosition.x <= 20 && linePosition.x >= -20) {
      setSupplyMoved(false);
      setLinePosition({ x: 0, y: 0 });
      setIsSupplyMoved(false);
    } else {
      setSupplyMoved(true);
      setIsSupplyMoved(true);
    }
  };

  const nothingMove = () => !supplyMoved;

  const setColors = () => {
    if (!supplyAnsweredCorrectly) setLineColor("red");
  };

  const nothingAsWrong = () => {
    // setDemandMoved(true)
    // setSupplyMoved(true)

    switch (answer) {
      case "shifts left":
        setShowLine(true);
        setCorrectPosition(-70);
        break;
      case "shifts right":
        setShowLine(true);
        setCorrectPosition(70);
        break;
    }
    setMoved("nothing");
    setAnsweredCorrectly(false);
    setSupplyAnsweredCorrectly(false);
  };

  const markQuestionAsWrong = () => {
    // setDemandMoved(true)
    // setSupplyMoved(true)

    switch (answer) {
      case "shifts left":
        setShowLine(true);
        setCorrectPosition(-70);
        !supplyMoved && setMoved("moved supply curve but in opposite");
        setColors();
        break;
      case "shifts right":
        setShowLine(true);
        setCorrectPosition(70);
        !supplyMoved && setMoved("moved supply curve but in opposite");
        setColors();
        break;
      default:
        setColors();
        if (!supplyMoved && answer.includes("shifts")) setMoved("incorrect");
    }
    setSupplyAnsweredCorrectly(false);
    // setAnsweredCorrectly(false)
  };

  const evaluateLineAnswer = (value) => {
    // if (value <= 10 && value >= -10)
    //   return
    // if (supplyMoved && answeredCorrectly === false) {
    //   return markQuestionAsWrong()
    // }

    let answerMatched = false;

    if (value > 10) {
      // setLinePosition({x: 75, y: 0})
      answerMatched = answer === "shifts right";
    } else if (value < -10) {
      // setLinePosition({x: -75, y: 0})
      answerMatched = answer === "shifts left";
    }
    // answer.includes("Supply curve shifts") && setFadeDot(0);
    console.log("answerMatched", answerMatched);
    answerMatched ? markQuestionAsCorrect() : markQuestionAsWrong();
  };

  const markQuestionAsCorrect = () => {
    // setDemandMoved(true)
    // setSupplyMoved(true)
    changePosition();
    setAnsweredCorrectly(true);
    setSupplyAnsweredCorrectly(true);
    // draggableDotColor()
    setMoved("correct");
  };
  const changePosition = () => {
    switch (answer) {
      case "shifts left":
        setLinePosition({ x: -70, y: 0 });
        break;
      case "shifts right":
        setLinePosition({ x: 70, y: 0 });
        break;
      default:
      // code block
    }
  };

  const draggableLineColor = () => {
    if (supplyAnsweredCorrectly && answer.includes("shifts")) {
      return "#508a05";
    }
    if (supplyAnsweredCorrectly && answer.includes("nothing")) {
      return;
    }
    return wrongPosition ? "#003E4C" : lineColor;
  };

  const lineMovedOrNotAnswered = () => {
    if (supplyAnsweredCorrectly === null) return true;
    if (supplyAnsweredCorrectly === false) return false;
    // if (answer.includes("Demand curve")) return true;
    if (linePosition.x !== 0 && supplyAnsweredCorrectly) return true;

    return false;
  };

  const showIconDefaultLine = () => {
    if (answer.includes("nothing")) return sBlue;
    return S1;
  };

  const p2ToOrigin = () => dotCenterPosition - 34;
  const p1ToCorrect = () =>
    !answer.includes("shifts right")
      ? dotCenterPosition - 70
      : dotCenterPosition;
  const q2ToCorrect = () =>
    answer.includes("shifts right") ? "265px" : "195px";
  const q1ToOrigin = () => "230px";
  const qTop = () => "330px";

  return (
    <div>
      <div className={classes.verticalLinesContainer}>
        <div className={arrowFadeIn}>
          <img
            src={arrowIcon}
            className={classes.arrows}
            style={{
              top: arrowPosition.top,
              left: arrowPosition.left,
              transform: answer.includes("shift")
                ? "rotate(0deg)"
                : "rotate(90deg)",
            }}
          />
        </div>
        <div className={classes.defaultLine}>
          {console.log("khuram: ", supplyAnsweredCorrectly)}
          {!(supplyAnsweredCorrectly === null) && (
            <img src={showIconDefaultLine()} className={classes.lineIcon} />
          )}
        </div>
        <div
          className={classes.correctLine}
          style={{
            zIndex: showLine ? "1" : "-1",
            backgroundColor: wrongPosition ? lineColor : "#508a05",
            transition: `left ${wrongPosition ? "0s" : "1s"}`,
            left: wrongPosition ? wrongPosition : correctPosition,
          }}
        >
          {showDot && !answeredCorrectly && (
            <div
              className={classes.correctDot}
              style={{
                opacity: "1",
                left: "-10px",
                top: !answer.includes("shifts right")
                  ? dotCenterPosition - 48
                  : dotCenterPosition + 50,
              }}
            />
          )}
          {changeIconColor && !supplyAnsweredCorrectly && (
            <img src={S2} className={classes.lineIcon} />
          )}
        </div>
        <Draggable
          axis="x"
          defaultPosition={{ x: 0, y: 0 }}
          position={linePosition}
          scale={1}
          bounds={{ top: 0, left: -70, right: 70, bottom: 0 }}
          onStart={DragStartLine}
          onDrag={DragLine}
          onStop={DragEndLine}
          // disabled={lineDisable}
        >
          <div style={{ cursor: !supplyMoved && "pointer" }}>
            <div
              className={classes.dragableLine}
              id="draggable_line"
              style={{
                backgroundColor: draggableLineColor(),
                opacity: draggableLineColor() == "red" ? 0.7 : 1,
              }}
            >
              {showDot && answeredCorrectly && (
                <div
                  className={classes.correctDot}
                  style={{
                    opacity: "1",
                    top: !answer.includes("shifts right")
                      ? dotCenterPosition - 48
                      : dotCenterPosition + 50,
                  }}
                />
              )}
              {lineMovedOrNotAnswered() && (
                <img
                  src={changeIconColor && supplyAnsweredCorrectly ? S2 : sBlue}
                  className={classes.lineIcon}
                />
              )}
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default withStyles(styles)(SupplyCurve);
