import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import LineDotLeftHandler from "./LineDotLeftHandler";
import LineDotBothHandler from "./LineDotBothHandler";
import LineDotRightHandler from "./LineDotRightHandler";
import model1 from "../../../images/double_line_icons/models/model1.svg";
import model2 from "../../../images/double_line_icons/models/model2.svg";
import model3 from "../../../images/double_line_icons/models/model3.svg";
import model4 from "../../../images/double_line_icons/models/model4.svg";
import model5 from "../../../images/double_line_icons/models/model5.svg";
import model6 from "../../../images/double_line_icons/models/model6.svg";
import styles from "./styles";
import "animate.css/animate.css";

const GraphLineDot = (props) => {
  const {
    classes,
    questionAnswer,
    setAnsweredCorrectly,
    answeredCorrectly,
    setSupplyAnsweredCorrectly,
    setDemandAnsweredCorrectly,
    supplyAnsweredCorrectly,
    demandAnsweredCorrectly,
    setMoved,
    submitted,
    setIsSupplyMoved,
    setIsDemandMoved,
    nextStageSubmitted,
    secondStage,
  } = props;

  const [showDragMessage, setShowDragMessage] = useState(false);
  const modelImages = [model1, model2, model3, model4, model5, model6];

  return (
    <Grid
      className={classes.quizContainer}
      style={{ marginRight: questionAnswer.model > 3 && secondStage && 0 }}
    >
      <Typography
        variant="h5"
        align="center"
        style={{
          paddingBottom: nextStageSubmitted && questionAnswer.model > 3 && 0,
        }}
        className={`${classes.questionTitle} animate__animated animate__bounceIn`}
      >
        {!(nextStageSubmitted && questionAnswer.model < 4) &&
          questionAnswer.title}
      </Typography>
      <Grid
        className={classes.graphDiv}
        style={{ marginBottom: nextStageSubmitted && "0px" }}
      >
        <Grid container justify="center" style={{ maxWidth: "520px" }}>
          {nextStageSubmitted ? (
            <img
              src={modelImages[questionAnswer.model - 1]}
              className={classes.secondAnswerImage}
            />
          ) : (
            <LineDotBothHandler
              showDragMessage={showDragMessage}
              questionAnswer={questionAnswer}
              setAnsweredCorrectly={setAnsweredCorrectly}
              answeredCorrectly={answeredCorrectly}
              setSupplyAnsweredCorrectly={setSupplyAnsweredCorrectly}
              setDemandAnsweredCorrectly={setDemandAnsweredCorrectly}
              supplyAnsweredCorrectly={supplyAnsweredCorrectly}
              demandAnsweredCorrectly={demandAnsweredCorrectly}
              setMoved={setMoved}
              setIsSupplyMoved={setIsSupplyMoved}
              setIsDemandMoved={setIsDemandMoved}
              submitted={submitted}
              nextStageSubmitted={nextStageSubmitted}
              secondStage={secondStage}
              setShowDragMessage={setShowDragMessage}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GraphLineDot);
